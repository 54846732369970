import { UpdateItemCacheKeys } from '../../../../types';

import {
  FileAttachmentUUID,
  UpdateFileAttachmentGqlQuery
} from '../../fileAttachmentsTypes';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

import { FileAttachmentItemImageVersions } from '../../../../helpers/ImageHelper';

interface UpdateFileAttachmentInfoOptions {
  query: UpdateFileAttachmentGqlQuery;
  cacheKeys?: UpdateItemCacheKeys;
}

export interface UpdateFileAttachmentInfoInput {
  clientMutationId?: string;
  uuid: FileAttachmentUUID;
  async?: boolean;
  versions?: FileAttachmentItemImageVersions[];
}

export interface UpdateFileAttachmentInfoError {
  fullMessages: string[] | null;
}

export interface UpdateFileAttachmentInfoResponse {
  updateFileAttachmentInfo: {
    status: string;
    record: {
      file: string;
    };
    errors: UpdateFileAttachmentInfoError;
  };
}

export const updateFileAttachmentInfoAction = 'updateFileAttachmentInfo';

function useUpdateFileAttachmentInfo({
  query,
  cacheKeys
}: UpdateFileAttachmentInfoOptions) {
  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    UpdateFileAttachmentInfoInput,
    UpdateFileAttachmentInfoResponse,
    UpdateFileAttachmentInfoError,
    undefined
  >({
    action: updateFileAttachmentInfoAction,
    cacheKeys,
    query
  });

  return {
    updateFileAttachmentInfoData: updateQueryData,
    updateFileAttachmentInfoError: updateQueryError,
    updateFileAttachmentInfoLoading: updateQueryLoading,
    updateFileAttachmentInfoErrorMessage: updateQueryErrorMessage,
    updateFileAttachmentInfo: updateQuery,
    updateFileAttachmentInfoReset: updateQueryReset
  };
}

export default useUpdateFileAttachmentInfo;
