import { UpdateItemCacheKeys } from '../../../../types';

import {
  ClientFileUUID,
  UpdateClientFileGqlQuery
} from '../../clientFilesTypes';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

import { ClientFileItemImageVersions } from '../../../../helpers/ImageHelper';

interface UpdateClientFileInfoOptions {
  query: UpdateClientFileGqlQuery;
  cacheKeys?: UpdateItemCacheKeys;
}

export interface UpdateClientFileInfoInput {
  clientMutationId?: string;
  uuid: ClientFileUUID;
  async?: boolean;
  versions?: ClientFileItemImageVersions[];
}

export interface UpdateClientFileInfoError {
  fullMessages: string[] | null;
}

export interface UpdateClientFileInfoResponse {
  updateClientFileInfo: {
    status: string;
    record: {
      file: string;
    };
    errors: UpdateClientFileInfoError;
  };
}

export const updateClientFileInfoAction = 'updateClientFileInfo';

function useUpdateClientFileInfo({
  query,
  cacheKeys
}: UpdateClientFileInfoOptions) {
  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    UpdateClientFileInfoInput,
    UpdateClientFileInfoResponse,
    UpdateClientFileInfoError,
    undefined
  >({
    action: updateClientFileInfoAction,
    cacheKeys,
    query
  });

  return {
    updateClientFileInfoData: updateQueryData,
    updateClientFileInfoError: updateQueryError,
    updateClientFileInfoLoading: updateQueryLoading,
    updateClientFileInfoErrorMessage: updateQueryErrorMessage,
    updateClientFileInfo: updateQuery,
    updateClientFileInfoReset: updateQueryReset
  };
}

export default useUpdateClientFileInfo;
