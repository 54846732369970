import {
  CreateFileImportGqlQuery,
  CreateFileImportGqlStatus,
  FetchFileImportsCacheKey,
  FileImportGqlError,
  FileImportID,
  FileImportName,
  FileImportNanoID,
  FileImportPresignedUrl,
  FileImportSize,
  FileImportUUID
} from '../../fileImportsTypes';

import { useCreateQuery } from '../../../common/hooks/base/reactQuery/useCreateQuery';

interface CreateFileImportOptions {
  query: CreateFileImportGqlQuery;
  cacheKeys?: FetchFileImportsCacheKey[];
}

interface CreateFileImportRecordResponse {
  id: FileImportID;
  uuid: FileImportUUID;
  presignedUrl: FileImportPresignedUrl;
}

export interface CreateFileImportResponse {
  createFileImport: {
    status: CreateFileImportGqlStatus;
    recordUuid: FileImportUUID;
    recordNanoId: FileImportNanoID;
    record: CreateFileImportRecordResponse;
    errors: CreateFileImportError;
  };
}

export interface CreateFileImportInput {
  name: FileImportName;
  size: FileImportSize;
}

export interface CreateFileImportError {
  fullMessages: FileImportGqlError;
}

const action = 'createFileImport';

function useCreateFileImport({ query, cacheKeys }: CreateFileImportOptions) {
  const {
    createQueryData,
    createQuery,
    createQueryReset,
    createQueryError,
    createQueryErrorMessage,
    createQueryLoading
  } = useCreateQuery<
    CreateFileImportInput,
    CreateFileImportResponse,
    CreateFileImportError,
    CreateFileImportRecordResponse
  >({ action, cacheKeys, query });

  return {
    createFileImportData: createQueryData,
    createFileImportError: createQueryError,
    createFileImportLoading: createQueryLoading,
    createFileImportErrorMessage: createQueryErrorMessage,
    createFileImport: createQuery,
    createFileImportReset: createQueryReset
  };
}

export default useCreateFileImport;
