import { S3MultipartFileType } from '../../s3MultipartTypes';

import { ImageVersions } from '../../../../helpers/ImageHelper';
import { BffApiRequest } from '../../../../utils/BffApiRequest';

import { ImgProxyApiUrl } from '../../../../api/imgProxy/ImgProxyApiUrl';

async function fallbackS3Upload(
  fileUrl: string,
  fileName: string,
  versions: ImageVersions[],
  type: S3MultipartFileType | 'fileAttachment' | 'image'
) {
  const imageUrl = fileUrl?.split('?')?.[0];

  await BffApiRequest.post<any>(ImgProxyApiUrl.resizeImage(), {
    versions,
    sourceImageUrl: imageUrl,
    sourceImageName: fileName,
    type
  });
}

export default fallbackS3Upload;
