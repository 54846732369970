import { gql } from 'graphql-request';

export const UPDATE_FILE_IMPORT_INFO_QUERY = gql`
  mutation UpdateFileImportInfo($uuid: ID!, $async: Boolean) {
    updateFileImportInfo(input: { uuid: $uuid, async: $async }) {
      status
      errors {
        fullMessages
      }
      record {
        id
        file
      }
    }
  }
`;
