import { UpdateItemCacheKeys } from '../../../../types';

import {
  FileImportUUID,
  UpdateFileImportGqlQuery
} from '../../fileImportsTypes';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

interface UpdateFileImportInfoOptions {
  query: UpdateFileImportGqlQuery;
  cacheKeys?: UpdateItemCacheKeys;
}

export interface UpdateFileImportInfoInput {
  clientMutationId?: string;
  uuid: FileImportUUID;
  async?: boolean;
}

export interface UpdateFileImportInfoError {
  fullMessages: string[] | null;
}

export interface UpdateFileImportInfoResponse {
  updateFileImportInfo: {
    status: string;
    record: {
      file: string;
    };
    errors: UpdateFileImportInfoError;
  };
}

export const updateFileImportInfoAction = 'updateFileImportInfo';

function useUpdateFileImportInfo({
  query,
  cacheKeys
}: UpdateFileImportInfoOptions) {
  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    UpdateFileImportInfoInput,
    UpdateFileImportInfoResponse,
    UpdateFileImportInfoError,
    undefined
  >({
    action: updateFileImportInfoAction,
    cacheKeys,
    query
  });

  return {
    updateFileImportInfoData: updateQueryData,
    updateFileImportInfoError: updateQueryError,
    updateFileImportInfoLoading: updateQueryLoading,
    updateFileImportInfoErrorMessage: updateQueryErrorMessage,
    updateFileImportInfo: updateQuery,
    updateFileImportInfoReset: updateQueryReset
  };
}

export default useUpdateFileImportInfo;
