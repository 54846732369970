import {
  CreateSourceFileGqlQuery,
  CreateSourceFileGqlStatus,
  FetchSourceFilesCacheKey,
  SourceFileGqlError,
  SourceFileID,
  SourceFileName,
  SourceFileNanoID,
  SourceFilePresignedUrl,
  SourceFileSize,
  SourceFileUUID
} from '../../sourceFilesTypes';

import { useCreateQuery } from '../../../common/hooks/base/reactQuery/useCreateQuery';

interface CreateSourceFileOptions {
  query: CreateSourceFileGqlQuery;
  cacheKeys?: FetchSourceFilesCacheKey[];
}

interface CreateSourceFileRecordResponse {
  id: SourceFileID;
  uuid: SourceFileUUID;
  presignedUrl: SourceFilePresignedUrl;
}

export interface CreateSourceFileResponse {
  createSourceFile: {
    status: CreateSourceFileGqlStatus;
    recordUuid: SourceFileUUID;
    recordNanoId: SourceFileNanoID;
    record: CreateSourceFileRecordResponse;
    errors: CreateSourceFileError;
  };
}

export interface CreateSourceFileInput {
  name: SourceFileName;
  size: SourceFileSize;
}

export interface CreateSourceFileError {
  fullMessages: SourceFileGqlError;
}

const action = 'createSourceFile';

function useCreateSourceFile({ query, cacheKeys }: CreateSourceFileOptions) {
  const {
    createQueryData,
    createQuery,
    createQueryReset,
    createQueryError,
    createQueryErrorMessage,
    createQueryLoading
  } = useCreateQuery<
    CreateSourceFileInput,
    CreateSourceFileResponse,
    CreateSourceFileError,
    CreateSourceFileRecordResponse
  >({ action, cacheKeys, query });

  return {
    createSourceFileData: createQueryData,
    createSourceFileError: createQueryError,
    createSourceFileLoading: createQueryLoading,
    createSourceFileErrorMessage: createQueryErrorMessage,
    createSourceFile: createQuery,
    createSourceFileReset: createQueryReset
  };
}

export default useCreateSourceFile;
