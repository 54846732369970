import { UpdateItemCacheKeys } from '../../../../types';

import { MaxFileUUID, UpdateMaxFileGqlQuery } from '../../maxFilesTypes';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

interface UpdateMaxFileInfoOptions {
  query: UpdateMaxFileGqlQuery;
  cacheKeys?: UpdateItemCacheKeys;
}

export interface UpdateMaxFileInfoInput {
  clientMutationId?: string;
  uuid: MaxFileUUID;
  async: boolean;
}

export interface UpdateMaxFileInfoError {
  fullMessages: string[] | null;
}

export interface UpdateMaxFileInfoResponse {
  updateMaxFileInfo: {
    status: string;
    record: {
      file: string;
    };
    errors: UpdateMaxFileInfoError;
  };
}

export const updateMaxFileInfoAction = 'updateMaxFileInfo';

function useUpdateMaxFileInfo({ query, cacheKeys }: UpdateMaxFileInfoOptions) {
  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    UpdateMaxFileInfoInput,
    UpdateMaxFileInfoResponse,
    UpdateMaxFileInfoError,
    undefined
  >({
    action: updateMaxFileInfoAction,
    cacheKeys,
    query
  });

  return {
    updateMaxFileInfoData: updateQueryData,
    updateMaxFileInfoError: updateQueryError,
    updateMaxFileInfoLoading: updateQueryLoading,
    updateMaxFileInfoErrorMessage: updateQueryErrorMessage,
    updateMaxFileInfo: updateQuery,
    updateMaxFileInfoReset: updateQueryReset
  };
}

export default useUpdateMaxFileInfo;
