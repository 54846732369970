import { gql } from 'graphql-request';

export const UPDATE_FILE_ATTACHMENT_INFO_QUERY = gql`
  mutation UpdateFileAttachmentInfo(
    $uuid: ID!
    $async: Boolean
    $versions: [String!]
  ) {
    updateFileAttachmentInfo(
      input: { uuid: $uuid, async: $async, versions: $versions }
    ) {
      status
      record {
        id
        file
      }
      errors {
        fullMessages
      }
    }
  }
`;
