import React, { Fragment, memo, useCallback } from 'react';
import split from 'lodash/split';
import keys from 'lodash/keys';
import last from 'lodash/last';
import values from 'lodash/values';
import sizeOf from 'lodash/size';
import sum from 'lodash/sum';

import { IconsEnum } from '../../../assets/icons/types';

import { AdminPermissions } from '../../../main/admin/adminConstants';

import { Icon } from '../../Icon';
import { PureIconButtonHelper } from '../../buttons/PureIconButtonHelper';
import { CheckPermissions } from '../../CheckPermissions';
import { ProgressCircleHelper } from '../../ProgressCircleHelper';
import {
  FileAttachmentItemImageVersions,
  ImageHelper
} from '../../ImageHelper';
import { SizeHelper } from '../../SizeHelper';
import { Translate } from '../../Translate';
import { Files } from '../../../utils/Files';

import { filesKeys } from '../../../locales/keys';

import { DropzoneUploadedFileItem } from './DropzoneUploadedFile.types';

interface DropzoneUploadedFileProps {
  id: string;
  onRemove?: (id: string) => void;
  onDownload?: (id: string) => void;
  withoutDownload?: boolean;
  withoutRemoveButton?: boolean;
  withoutStatus?: boolean;
}

function DropzoneUploadedFile({
  id,
  name,
  file,
  size,
  progresses,
  state,
  onRemove,
  onDownload,
  withoutDownload,
  withoutRemoveButton,
  withoutStatus
}: DropzoneUploadedFileProps & DropzoneUploadedFileItem) {
  const extension = last(split(name, '.'));
  const progress = Math.round(
    sum(values(progresses)) / (sizeOf(keys(progresses)) || 1)
  );

  const handleRemove = useCallback<() => void>(
    () => onRemove?.(id),
    [id, onRemove]
  );

  const handleDownload = useCallback<() => void>(
    () => onDownload?.(id),
    [id, onDownload]
  );

  return (
    <li key={id} className="flex space-x-3 group">
      <div className="flex-shrink-0 relative">
        {Files.isImage(name) ? (
          <ImageHelper
            className="w-14 h-14 rounded-md object-cover object-center"
            src={file}
            alt={name}
            version={FileAttachmentItemImageVersions.MiniThumb144x144}
          />
        ) : (
          <div className="flex w-14 h-14 items-center justify-center rounded-md bg-gray-200 dark:bg-gray-800 text-gray-500">
            <Icon icon={IconsEnum.DOCUMENT_SOLID} />
          </div>
        )}

        {withoutDownload || state !== 'finished' ? null : (
          <PureIconButtonHelper
            className={
              'absolute inset-0 rounded-md flex items-center justify-center opacity-0 group-hover:opacity-100 group-focus:opacity-100 bg-black bg-opacity-50 text-white'
            }
            icon={IconsEnum.DOWNLOAD_SOLID}
            onClick={handleDownload}
          />
        )}
      </div>
      <div className="flex-1 flex items-center overflow-hidden">
        <div className="overflow-hidden">
          <p className="truncate w-full flex text-sm mb-0.5">
            <span className="truncate">{name?.replace(extension, '')}</span>
            <span>{extension}</span>
          </p>
          <div className="text-gray-500 text-2xs flex gap-2">
            <span className="whitespace-nowrap">
              <SizeHelper size={size} />
              <CheckPermissions action={AdminPermissions.ADMIN_READ_ANY_ID}>
                <br />
                {keys(progresses).map((p) => (
                  <Fragment key={p}>
                    {p} - {progresses[p]}%&nbsp;
                    <br />
                  </Fragment>
                ))}
              </CheckPermissions>
            </span>
            {state === 'failed' && !withoutStatus ? (
              <p className="truncate text-red-500 text-2xs align-center">
                <Icon
                  className="h-4 w-4 -mt-0.5 inline-block"
                  icon={IconsEnum.EXCLAMATION_CIRCLE}
                />
                <span>
                  <Translate id={filesKeys.failedToUpload} />
                </span>
              </p>
            ) : null}
          </div>
        </div>
      </div>
      <div className="flex-shrink-0">
        <div className="h-12 w-12 my-1 relative group">
          {state === 'finished' && !withoutStatus ? (
            <div className="flex items-center justify-center absolute inset-0 text-blue-500">
              <Icon icon={IconsEnum.CHECK} />
            </div>
          ) : null}

          {state === 'finished' || withoutStatus ? null : (
            <ProgressCircleHelper
              color={state === 'failed' ? 'red' : 'blue'}
              progress={progress > 99 ? 99 : progress}
            />
          )}

          {withoutRemoveButton ? null : (
            <div className="flex items-center justify-center absolute inset-0 opacity-0 group-hover:opacity-100 focus-within:opacity-100">
              <PureIconButtonHelper
                className="dark:text-gray-200 bg-gray-200 focus:ring-offset-0 dark:bg-gray-800 text-gray-800 p-2 rounded-full focus:opacity-100"
                icon={IconsEnum.X}
                onClick={handleRemove}
              />
            </div>
          )}
        </div>
      </div>
    </li>
  );
}

export default memo<DropzoneUploadedFileProps>(DropzoneUploadedFile);
