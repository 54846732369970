import React, { Fragment, useCallback } from 'react';
import keys from 'lodash/keys';

import {
  DropzoneUploadedFile,
  DropzoneUploadedFileItem
} from '../DropzoneUploadedFile';

interface DropzoneUploadedFilesProps {
  activeTab: string;
  allFiles: { [id: string]: DropzoneUploadedFileItem & { activeTab: string } };
  onRemoveFile?: (id: string) => void;
  onDownloadFile?: (id: string) => void;
  withoutDownload?: boolean;
  withoutRemoveButton?: boolean;
  withoutStatus?: boolean;
}

function DropzoneUploadedFiles({
  activeTab,
  allFiles,
  onRemoveFile,
  onDownloadFile,
  withoutDownload,
  withoutRemoveButton,
  withoutStatus
}: DropzoneUploadedFilesProps) {
  const handleRemove = useCallback<(id: string) => void>(
    (id: string) => {
      onRemoveFile(id);
    },
    [onRemoveFile]
  );

  const handleDownload = useCallback<(id: string) => void>(
    (id: string) => {
      onDownloadFile(id);
    },
    [onDownloadFile]
  );

  return (
    <div className="mt-3 -mr-3 sm:mr-0">
      <ul className="space-y-2 w-full">
        {keys(allFiles).map((id) => (
          <Fragment key={id}>
            {allFiles[id].activeTab === activeTab ? (
              <DropzoneUploadedFile
                id={id}
                {...allFiles[id]}
                onRemove={handleRemove}
                onDownload={handleDownload}
                withoutDownload={withoutDownload}
                withoutRemoveButton={withoutRemoveButton}
                withoutStatus={withoutStatus}
              />
            ) : null}
          </Fragment>
        ))}
      </ul>
    </div>
  );
}

export default DropzoneUploadedFiles;
