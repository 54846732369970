import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  CSSProperties,
  Fragment
} from 'react';
import { createPortal } from 'react-dom';
import max from 'lodash/max';

import { IconsEnum } from '../../../assets/icons/types';

import { Icon } from '../../Icon';
import { Translate } from '../../Translate';

import { MODAL_QUERY_SELECTOR } from '../../modals/modalsConstants';

import { stringsKeys } from '../../../locales/keys';

interface DropzoneFullscreenHelperProps {
  style?: CSSProperties;
}

function DropzoneFullscreenHelper({ style }: DropzoneFullscreenHelperProps) {
  const anchorElementRef = useRef<HTMLDivElement | null>(null);
  const [isDraggable, setIsDraggable] = useState<boolean>(false);

  const [isDragging, setIsDragging] = useState<boolean>(false);
  const dragCounter = useRef<number>(0);

  const handleDraggingStart = useCallback<() => void>(() => {
    setIsDragging(true);
    const modalElement = document.querySelector(MODAL_QUERY_SELECTOR);
    if (
      !modalElement ||
      (modalElement &&
        anchorElementRef.current &&
        modalElement.contains(anchorElementRef.current))
    ) {
      setIsDraggable(true);
    }
  }, []);

  const handleDraggingEnd = useCallback<() => void>(() => {
    setIsDragging(false);
    setIsDraggable(false);
  }, []);

  const handleDragIn = useCallback<() => void>(() => {
    dragCounter.current = dragCounter.current + 1;
    handleDraggingStart();
  }, [handleDraggingStart]);
  const handleDragOut = useCallback<() => void>(() => {
    dragCounter.current = max([0, dragCounter.current - 1]);
    if (dragCounter.current > 0) {
      return;
    }
    handleDraggingEnd();
  }, [handleDraggingEnd]);
  const handleDrop = useCallback<() => void>(() => {
    handleDraggingEnd();
    dragCounter.current = 0;
  }, [handleDraggingEnd]);

  useEffect(() => {
    window.addEventListener('dragenter', handleDragIn);
    window.addEventListener('dragleave', handleDragOut);
    window.addEventListener('drop', handleDrop);
    return function cleanUp() {
      window.removeEventListener('dragenter', handleDragIn);
      window.removeEventListener('dragleave', handleDragOut);
      window.removeEventListener('drop', handleDrop);
    };
  }, [handleDragIn, handleDragOut, handleDrop]);

  if (!isDragging) {
    return null;
  }

  return (
    <Fragment>
      <div
        className="absolute w-0 h-0 block invisible opacity-0 -z-1"
        ref={anchorElementRef}
      />
      {isDraggable
        ? createPortal(
            <div className="p-1">
              {isDraggable ? (
                <div
                  className="z-20 fixed top-0 left-0 right-0 bottom-0 flex border-2 border-dashed border-gray-300 dark:border-gray-700 rounded-md text-sm bg-gray-900/25 dark:bg-gray-100/25 text-gray-500 dark:text-gray-300"
                  style={style}
                >
                  <div className="m-auto flex flex-col text-center">
                    <div className="mx-auto">
                      <Icon
                        className="h-16 w-16"
                        icon={IconsEnum.CLOUD_UPLOAD_OUTLINE}
                      />
                    </div>
                    <div className="mx-auto mt-2">
                      <span className="text-2xl">
                        <Translate id={stringsKeys.dropTheFilesHere} />
                      </span>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>,
            window.document.body
          )
        : null}
    </Fragment>
  );
}

export default DropzoneFullscreenHelper;
