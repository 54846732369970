import {
  CreateClientFileGqlQuery,
  CreateClientFileGqlStatus,
  FetchClientFilesCacheKey,
  ClientFileGqlError,
  ClientFileID,
  ClientFileName,
  ClientFileNanoID,
  ClientFilePresignedUrl,
  ClientFileSize,
  ClientFileUUID
} from '../../clientFilesTypes';

import { useCreateQuery } from '../../../common/hooks/base/reactQuery/useCreateQuery';

interface CreateClientFileOptions {
  query: CreateClientFileGqlQuery;
  cacheKeys?: FetchClientFilesCacheKey[];
}

interface CreateClientFileRecordResponse {
  id: ClientFileID;
  uuid: ClientFileUUID;
  presignedUrl: ClientFilePresignedUrl;
  presignedUrls: ClientFilePresignedUrl;
}

export interface CreateClientFileResponse {
  createClientFile: {
    status: CreateClientFileGqlStatus;
    recordUuid: ClientFileUUID;
    recordNanoId: ClientFileNanoID;
    record: CreateClientFileRecordResponse;
    errors: CreateClientFileError;
  };
}

export interface CreateClientFileInput {
  name: ClientFileName;
  size: ClientFileSize;
}

export interface CreateClientFileError {
  fullMessages: ClientFileGqlError;
}

const action = 'createClientFile';

function useCreateClientFile({ query, cacheKeys }: CreateClientFileOptions) {
  const {
    createQueryData,
    createQuery,
    createQueryReset,
    createQueryError,
    createQueryErrorMessage,
    createQueryLoading
  } = useCreateQuery<
    CreateClientFileInput,
    CreateClientFileResponse,
    CreateClientFileError,
    CreateClientFileRecordResponse
  >({ action, cacheKeys, query });

  return {
    createClientFileData: createQueryData,
    createClientFileError: createQueryError,
    createClientFileLoading: createQueryLoading,
    createClientFileErrorMessage: createQueryErrorMessage,
    createClientFile: createQuery,
    createClientFileReset: createQueryReset
  };
}

export default useCreateClientFile;
