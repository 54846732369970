import { UpdateItemCacheKeys } from '../../../../types';

import {
  SourceFileUUID,
  UpdateSourceFileGqlQuery
} from '../../sourceFilesTypes';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

import { SourceFileItemImageVersions } from '../../../../helpers/ImageHelper';

interface UpdateSourceFileInfoOptions {
  query: UpdateSourceFileGqlQuery;
  cacheKeys?: UpdateItemCacheKeys;
}

export interface UpdateSourceFileInfoInput {
  clientMutationId?: string;
  uuid: SourceFileUUID;
  async?: boolean;
  versions?: SourceFileItemImageVersions[];
}

export interface UpdateSourceFileInfoError {
  fullMessages: string[] | null;
}

export interface UpdateSourceFileInfoResponse {
  updateSourceFileInfo: {
    status: string;
    record: {
      file: string;
    };
    errors: UpdateSourceFileInfoError;
  };
}

export const updateSourceFileInfoAction = 'updateSourceFileInfo';

function useUpdateSourceFileInfo({
  query,
  cacheKeys
}: UpdateSourceFileInfoOptions) {
  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    UpdateSourceFileInfoInput,
    UpdateSourceFileInfoResponse,
    UpdateSourceFileInfoError,
    undefined
  >({
    action: updateSourceFileInfoAction,
    cacheKeys,
    query
  });

  return {
    updateSourceFileInfoData: updateQueryData,
    updateSourceFileInfoError: updateQueryError,
    updateSourceFileInfoLoading: updateQueryLoading,
    updateSourceFileInfoErrorMessage: updateQueryErrorMessage,
    updateSourceFileInfo: updateQuery,
    updateSourceFileInfoReset: updateQueryReset
  };
}

export default useUpdateSourceFileInfo;
