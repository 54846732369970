import {
  CreateImageGqlQuery,
  CreateImageGqlStatus,
  FetchImagesCacheKey,
  ImageGqlError,
  ImageID,
  ImageName,
  ImageSize,
  ImageNanoID,
  ImagePresignedUrl,
  ImageUUID,
  ImageFile
} from '../../imagesTypes';

import { useCreateQuery } from '../../../common/hooks/base/reactQuery/useCreateQuery';

interface CreateImageOptions {
  query: CreateImageGqlQuery;
  cacheKeys?: FetchImagesCacheKey[];
}

interface CreateImageRecordResponse {
  id: ImageID;
  uuid: ImageUUID;
  presignedUrl: ImagePresignedUrl;
  file: ImageFile;
  size: ImageSize;
  name: ImageName;
}

export interface CreateImageResponse {
  createImage: {
    status: CreateImageGqlStatus;
    recordUuid: ImageUUID;
    recordNanoId: ImageNanoID;
    record: CreateImageRecordResponse;
    errors: CreateImageError;
  };
}

export interface CreateImageInput {
  name: ImageName;
  size: ImageSize;
}

export interface CreateImageError {
  fullMessages: ImageGqlError;
}

const action = 'createImage';

function useCreateImage({ query, cacheKeys }: CreateImageOptions) {
  const {
    createQueryData,
    createQuery,
    createQueryReset,
    createQueryError,
    createQueryErrorMessage,
    createQueryLoading
  } = useCreateQuery<
    CreateImageInput,
    CreateImageResponse,
    CreateImageError,
    CreateImageRecordResponse
  >({ action, cacheKeys, query });

  return {
    createImageData: createQueryData,
    createImageError: createQueryError,
    createImageLoading: createQueryLoading,
    createImageErrorMessage: createQueryErrorMessage,
    createImage: createQuery,
    createImageReset: createQueryReset
  };
}

export default useCreateImage;
